<template>
  <div>
    <edit-building
      data-test-id="edit-building"
      :project-id="projectId"
      :building-id="buildingId"
      :title="$t('registerProjectTitle')"
      submit-text="submitProject"
      :handle-submit="submitRegistration"
    />
  </div>
</template>

<script>
import EditBuilding from '@/components/building/edit/EditBuilding'
import submitProjectRegistration from '@/graphql/accreditations/projects/submitProjectRegistration.graphql'
import { mapGetters } from 'vuex'
import { Consts } from '@/_helpers'
import alerts from '@/_helpers/alerts'

export default {
  components: {
    EditBuilding
  },
  props: {
    projectId: String,
    buildingId: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID
    })
  },
  methods: {
    submitRegistration() {
      return this.$apollo.mutate({
        mutation: submitProjectRegistration,
        variables: {
          input: {
            accreditationId: this.accreditationId,
            projectId: this.projectId
          }
        }
      })
        .then(data => {
          alerts.showInfo('submitProjectRegistrationSuccess')
          return data.data.submitProjectRegistration
        })
    }
  }
}
</script>